import { useEffect, useState } from "react"
import { UsersIcon } from "@heroicons/react/outline"
import { Combobox } from "@headlessui/react"
import { useDebounce } from "usehooks-ts"
import { Property, PropertyStatus } from "../../../types/property"
import { getEndpoint } from "../../../util/api"
import { formatAddress } from "../../../util/addresses"
import {
  ListResponse,
  PropertyListItem,
} from "../../../ostrich/rpc/properties/properties/v1_pb"

type Props = {
  onSelect: (property: Property) => void
}

// TEMP: Nasty, but avoids us having to change everything up the tree for now
const mapToLegacyProperty = (property: PropertyListItem): Property => {
  return {
    id: property.id,
    users: property.users?.map((user) => {
      return {
        id: user.id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        // These required fields don't exist in PropertyListItem.User,
        // but we can set them to anything as they're not used here
        signupType: "",
        verified: { email: false, stripe: "" },
      }
    }),
    // These required fields don't exist in PropertyListItem,
    // but we can set them to anything as they're not used here
    attributes: {},
    councilTax: "",
    featured: false,
    saleTimeline: "",
    status: PropertyStatus.Live,
  }
}

export const PropertySearch: React.FC<Props> = ({ onSelect }) => {
  const [query, setQuery] = useState("")
  const [loading, setLoading] = useState(false)
  const [properties, setProperties] = useState<PropertyListItem[]>([])

  const debouncedQuery = useDebounce(query, 500)

  useEffect(() => {
    const lookupUsers = async (query: string) => {
      if (!query) return []

      setLoading(true)

      const response = await fetch(
        `${getEndpoint()}/properties/?search=${encodeURIComponent(query)}`,
        {
          cache: "no-cache",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        },
      )

      const json = (await response.json()) as ListResponse

      setLoading(false)

      setProperties(json.properties ?? [])
    }

    lookupUsers(debouncedQuery)
  }, [debouncedQuery])

  const handleSelect = (propertyId: string) => {
    let property = properties.find((property) => property.id === propertyId)

    if (property) {
      onSelect(mapToLegacyProperty(property))
    }
  }

  return (
    <Combobox onChange={handleSelect} value="">
      <Combobox.Input
        className="w-full rounded-md border-0 bg-gray-100 px-4 py-2.5 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
        placeholder="Search by address or ID..."
        onChange={(event) => setQuery(event.target.value)}
      />

      {properties.length > 0 && (
        <Combobox.Options
          static
          className="-mb-2 max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800"
        >
          {properties.map((property) => (
            <Combobox.Option
              key={property.id}
              value={property.id}
              className={({ active }) =>
                `cursor-default select-none rounded-md px-4 py-2 ${
                  active ? "bg-indigo-600 text-white" : ""
                }`
              }
            >
              {({ active }) => (
                <div className="flex flex-col justify-between gap-1 sm:flex-row sm:items-center">
                  <div className="flex-shrink-0">
                    <p className="flex gap-1">
                      {property.displayAddress}
                      {property.address &&
                        formatAddress(property.address, true)}
                      {property.status === "H" && (
                        <span
                          className={active ? "text-gray-300" : "text-gray-500"}
                        >
                          &ndash; Unverified
                        </span>
                      )}
                    </p>
                    {property.users && (
                      <p className={active ? "text-gray-300" : "text-gray-500"}>
                        {property.users.map((user) => user.email).join(", ")}
                      </p>
                    )}
                  </div>
                  <p
                    className={`truncate font-mono text-xs leading-none ${
                      active ? "text-gray-300" : "text-gray-500"
                    }`}
                  >
                    {property.id}
                  </p>
                </div>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      )}
      {debouncedQuery !== "" && !loading && properties.length === 0 && (
        <div className="py-14 px-4 text-center sm:px-14">
          <UsersIcon
            className="mx-auto h-6 w-6 text-gray-400"
            aria-hidden="true"
          />
          <p className="mt-4 text-sm text-gray-900">No users found.</p>
        </div>
      )}
    </Combobox>
  )
}
