import { ReactElement } from "react"
import { FilterOption } from "../../global/filter-option"
import { ListingAppointmentsFilters } from "../../../redux/slices/listing-appointments-list"
import { SelectField } from "../../global/forms/select-field"
import { SelectOption } from "../../../types/forms"

const statuses = [
  { key: "CO", label: "Confirmed" },
  { key: "PO", label: "Postponed" },
  { key: "CA", label: "Cancelled" },
]

const photographers: SelectOption[] = [
  { label: "Ignore", value: undefined },
  { label: "Daisy", value: "DA" },
  { label: "DJD", value: "DJ" },
  { label: "Other", value: "OT" },
  { label: "Unset", value: "UN" },
]

interface ListingAppointmentFiltersPanelProps {
  filters: ListingAppointmentsFilters
  onChange: (filters: ListingAppointmentsFilters) => void
}

export const ListingAppointmentFiltersPanel: React.FC<
  ListingAppointmentFiltersPanelProps
> = ({ filters, onChange }): ReactElement => {
  const changeStatus = (status?: string) => {
    let updatedStatus: string[] = []

    if (status) {
      if (filters.status?.includes(status)) {
        updatedStatus = filters.status.filter((s) => s !== status)
      } else {
        updatedStatus = filters.status ? [...filters.status, status] : [status]
      }
    }

    if (
      updatedStatus.length === statuses.length ||
      updatedStatus.length === 0
    ) {
      updatedStatus = []
    }

    onChange({ ...filters, status: updatedStatus })
  }

  const changePhotographer = (photographer?: string) => {
    onChange({ ...filters, photographer })
  }

  return (
    <div className="bg-white justify-between text-sm text-gray-800 shadow mb-6 flex items-start lg:items-center flex-col lg:flex-row sm:rounded-md sm:px-6 lg:mb-8">
      <div className="flex items-center divide-x">
        <div className="flex h-full items-center">
          <span className="w-1/3 p-4 font-medium sm:w-auto sm:pl-0">
            Status
          </span>
        </div>
        <FilterOption
          label="All"
          checked={!filters.status || filters.status.length === 0}
          onToggle={() => changeStatus()}
        />
        <div className="grid grid-cols-5 divide-x">
          {statuses.map(({ key, label }) => (
            <FilterOption
              key={key}
              label={label}
              checked={Boolean(filters.status?.includes(key))}
              onToggle={() => changeStatus(key)}
            />
          ))}
        </div>
      </div>
      <div className="flex flex-row items-center gap-2 border-l">
        <span className="p-4 font-medium sm:w-auto">Photographer</span>
        <SelectField
          className="w-[150px]"
          id="photographer"
          options={photographers}
          value={filters.photographer}
          onChange={(value) => changePhotographer(value)}
        />
      </div>
      <div className="flex items-center border-l">
        <FilterOption
          label="Include past appointments"
          checked={filters.includePastAppointments || false}
          onToggle={() => {
            onChange({
              ...filters,
              includePastAppointments: !filters.includePastAppointments,
            })
          }}
        />
      </div>
    </div>
  )
}
