import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getEndpoint } from "../../util/api"
import { ListResponse } from "../../ostrich/rpc/users/listing_appointments/v1_pb"
import { BaseFilters } from "../../types/filters"

export interface ListingAppointmentsFilters extends BaseFilters {
  status?: string[]
  photographer?: string
  date?: string
  includePastAppointments?: boolean
}

export const decodeFilters = (
  searchParams: URLSearchParams,
): ListingAppointmentsFilters => {
  const filters: ListingAppointmentsFilters = {}

  const status = searchParams
    .get("status")
    ?.split(",")
    .filter((s) => s.length)

  if (status?.length) {
    filters.status = status
  }

  const search = searchParams.get("search")

  if (search) {
    filters.searchQuery = search
  }

  const date = searchParams.get("date")

  if (date) {
    filters.date = date
  }

  const page = searchParams.get("page")

  if (page) {
    filters.page = parseInt(page)
  }

  const includePastAppointments = searchParams.get("includePastAppointments")

  if (includePastAppointments) {
    filters.includePastAppointments = includePastAppointments === "true"
  }

  const photographer = searchParams.get("photographer")

  if (photographer) {
    filters.photographer = photographer
  }

  return filters
}

export const encodeFilters = (
  filters: ListingAppointmentsFilters,
): URLSearchParams => {
  const params = new URLSearchParams()

  if (filters.status?.length) {
    params.set("status", filters.status.join(","))
  }

  if (filters.searchQuery) {
    params.set("search", filters.searchQuery)
  }

  if (filters.date) {
    params.set("date", filters.date)
  }

  if (filters.page) {
    params.set("page", filters.page.toString())
  }

  if (filters.includePastAppointments) {
    params.set(
      "includePastAppointments",
      filters.includePastAppointments.toString(),
    )
  }

  if (filters.photographer) {
    params.set("photographer", filters.photographer)
  }

  return params
}

const initialState: ListResponse = {
  appointments: [],
  datesWithAppointments: [],
  pagination: {
    totalCount: 0,
    totalPages: 0,
    currentPage: 1,
    pageSize: 20,
  },
}

export const fetchListingAppointments = createAsyncThunk(
  "listingAppointmentsList/fetchListingAppointments",
  async (filters: ListingAppointmentsFilters, thunkAPI) => {
    const encodedFilters = encodeFilters(filters)

    const response = await fetch(
      `${getEndpoint()}/bookings/?${encodedFilters.toString()}`,
      {
        cache: "no-cache",
        credentials: "include",
      },
    )

    const json = (await response.json()) as ListResponse

    return json
  },
)

export const listingAppointmentsListSlice = createSlice({
  name: "listingAppointmentsList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchListingAppointments.fulfilled, (state, action) => {
      state.appointments = action.payload.appointments
      state.pagination = action.payload.pagination
      state.datesWithAppointments = action.payload.datesWithAppointments
    })
  },
})

export const listingAppointmentsListReducer =
  listingAppointmentsListSlice.reducer
