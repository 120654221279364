import { ReactElement } from "react"
import { FormPanel } from "../../global/forms/form-panel"
import { Link } from "react-router-dom"
import { formatDateTime } from "../../../util/dates"
import { ViewingRequestListItem } from "../../../ostrich/rpc/users/viewing_requests/v2_pb"
import { OfferComputedStatusLabel } from "../../offer-list/computed-status-label"

type Props = {
  offers?: ViewingRequestListItem[]
}

export const OffersTab: React.FC<Props> = ({ offers }): ReactElement => {
  return (
    <>
      <FormPanel title="Offers">
        {offers && offers.length > 0 ? (
          <table className="col-span-2 min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="w-1/5 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                >
                  User
                </th>
                <th
                  scope="col"
                  className="w-1/5 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                >
                  Offer
                </th>
                <th
                  scope="col"
                  className="w-1/5 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="w-1/5 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="relative py-3.5 pl-3 pr-4 sm:pr-6 md:pr-0"
                >
                  <span className="sr-only">View</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {offers?.map((offer) => (
                <tr key={offer.id}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0">
                    {offer.buyer?.name}
                    {offer.buyer?.verifiedStripe !== "VE" && (
                      <span className="ml-1 font-normal text-gray-500">
                        &ndash; Unverified
                      </span>
                    )}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {offer.offer
                      ? `£${offer.offer?.toLocaleString()}`
                      : "Buying agent"}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <OfferComputedStatusLabel offer={offer} style="large" />
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {formatDateTime(offer.dateCreated)}
                  </td>
                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm sm:pr-6 md:pr-0">
                    <Link
                      to={`/offers/${offer.id}/`}
                      className="font-medium text-indigo-600 hover:text-indigo-900"
                      target="_blank"
                    >
                      View
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="col-span-2 text-sm text-gray-500">No offers.</p>
        )}
      </FormPanel>
    </>
  )
}
