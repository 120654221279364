export const classNames = (...classes: string[]): string => {
  return classes.filter(Boolean).join(" ")
}

export const buildingTypes = [
  "Apartment",
  "House",
  "Maisonette",
  "Mews House",
  "Penthouse",
  "Studio",
  "Townhouse",
]

export const waitlistedBuyerFundingPositions: { [key: string]: string } = {
  CB: "Cash Buyer",
  MA: "Mortgage Agreed in Principle",
  PS: "Property to Sell",
  SM: "Seeking Mortgage",
}

export const photographers: { [key: string]: string } = {
  DA: "Daisy",
  JO: "Joe",
  DJ: "DJD",
  OT: "Other",
}
